<!--
 * @Author: your name
 * @Date: 2022-04-06 11:44:59
 * @LastEditTime: 2022-04-27 14:29:57
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\components\smalltitle\index.vue
-->
<template>
  <div class="smalltitle">
    <div class="small-left">ID:{{iccid}}
      <div class="left-box">
        <div class="text">
          {{info.cur_buy_name}}
        </div>
      </div>
    </div>
    <div class="small-right" @click="setWifi">
      <div class="text">切换设备
        <div class="text-icon">{{info.devices}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return{

    }
  },
  props:{
    info:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    iccid:{
      type:String
    }
  },
  methods:{
    setWifi(){
       // 切换设备
      this.$emit("setWifi")
    }
  }
}
</script>
<style lang="less" scoped>
.smalltitle{
  width: 95%;
  margin:  0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  .small-left{
    font-size: 14px;
    color: #000;
    font-weight: 600;
    display: flex;
    justify-items: center;
    align-items: center;
    .left-box{
      height: 16px;
      border-radius: 3px;
      border: #82aef6 solid 1px;
      margin-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      .text{
        font-size: 12px;
        color: #1a6af1;
        transform: scale(0.9);
      }
    }
  }
  .small-right{
    width: 70px;
    height: 18px;
    background-color: #1a6af1;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    .text{
      font: 12px;
      transform: scale(0.9);
      color: #bfd1ff;
      display: flex;
      align-items: center;
      justify-content: center;
      .text-icon{
        height: 14px;
        width: 14px;
        border-radius: 100%;
        color: #fff;
        text-align: center;
        line-height: 14px;
        background-color: #5f97f5;
        margin-left: 2px;
      }
    }
  }
}
</style>