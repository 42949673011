<!--
 * @Author: your name
 * @Date: 2022-04-07 14:47:15
 * @LastEditTime: 2022-04-19 15:07:19
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\components\bluebutton\index.vue
-->
<template>
<div>
   <div class="button" @click="clkbutton" v-if="isloading">
    {{title}}
  </div>
  <div class="newbutton" v-else>
    <van-loading color="#fff" />{{istitle}}...
  </div>
</div>
</template>
<script>
export default {
  data() {
    return{

    }
  },
  props:{
    title:{
      type:String,
      default:"提交反馈"
    },
    isloading:{
      type:Boolean,
      default:true
    },
    istitle:{
      type:String,
      default:"支付中"
    }
  },
  methods:{
    clkbutton(){
      this.$emit("clkbutton")
    }
  }
}
</script>
<style lang="less" scoped>
.button{
  width: 240px;
  height: 48px;
  background: linear-gradient(to bottom left,#3393f4,30%,#236fed);
  color: #fff;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  border-radius: 8px 8px 4px 4px;
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;  
}
.newbutton{
  width: 240px;
  height: 48px;
  background: linear-gradient(to bottom left,#3393f4,30%,#236fed);
  color: #fff;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  border-radius: 8px 8px 4px 4px;
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center; 
}
</style>